<template>
  <section>
    <h2>Contact</h2>
    <p>Wil je hulp, heb je een vraag of wil met mij samenwerken?</p>
    <p>Neem gerust contact op met mij via <a href="https://wa.me/31642648564">WhatsApp</a> / telefoon:
      <a href="tel:0642648564">0642648564</a> of
      <a href="https://www.linkedin.com/in/marianne-joosen-321b4a52/" target="_blank">LinkedIn</a>.</p>
    <p>Wanneer ik werkzaamheden verricht zoals het uitvoeren van diagnostiek of het
    geven van een behandeling, ben ik niet in in de gelegenheid om de telefoon op te nemen.
  U kunt ten alle tijden een bericht achterlaten.
  Dan zal ik zo snel mogelijk contact met u opnemen.</p>

  </section>
</template>
<style scoped lang="scss">
section {
  text-align:left;
  padding: 10px;
}

#namen{
  flex-direction: column;
  display:flex;
  max-width: 400px;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 0.75rem;
  }

@media (min-width: 768px){
  #namen{
    flex-direction: row;
  }
}

.o-field{min-width: 200px;}

</style>
